@charset "utf-8";

@import "variables";


html,body,div,span,object,iframe,h1,h2,h3,h4,h5,h6,p,blockquote,pre,abbr,address,cite,code,del,dfn,em,img,ins,kbd,q,samp,small,strong,sub,sup,var,b,i,dl,dt,dd,ol,ul,li,fieldset,form,label,legend,input,textarea,button,table,caption,tbody,tfoot,thead,tr,th,td,article,aside,canvas,details,figcaption,figure,footer,header,hgroup,menu,nav,section,summary,time,mark,audio,video{margin:0;padding:0;border:0;outline:0;font-size:100%;font-weight:inherit;font-style:normal;vertical-align:baseline;}
body{-webkit-text-size-adjust:100%;line-height:1;}
article,aside,details,figcaption,figure,footer,header,hgroup,menu,nav,section{display:block}
ul,ol{list-style:none}
blockquote,q{quotes:none}
blockquote:before,blockquote:after,q:before,q:after{content:none}
a{margin:0;padding:0;font-size:100%;vertical-align:baseline;background:transparent;}
ins{background-color:#ff9;color:#000;text-decoration:none}
mark{background-color:#ff9;color:#000;font-style:italic;font-weight:bold}
del{text-decoration:line-through}
abbr[title],dfn[title]{border-bottom:1px dotted;cursor:help}
table{border-collapse:collapse;border-spacing:0}
input,select{vertical-align:middle}
a{color:inherit;text-decoration:none;outline:none;}
img{-ms-interpolation-mode: bicubic;}

::selection{background:#000;color:#fff;}
::-moz-selection{background:#000;color:#fff;}

*{
	box-sizing: border-box;
}

html{
	position: relative;
	overflow-x: hidden;
	min-width: 320px;
}
body{
	width: 100%;
	height: 100%;
	margin: 0;
	padding: 0;
	font-size: $fontSize;
	font-family: $fontBase;
	line-height: $lineHeight;
	letter-spacing: .1em;
	color: $textColor;
	background-color: #fff;
	overflow-x: hidden;
}
body:before{
	content: '';
	display: block;
	position: fixed;
	top: 0;
	left: 0;
	width: 25%;
	height: 100%;
	background: rgba(0,0,0,.6) url(../svg/common/bg_logo.svg) no-repeat center;
	background-size: cover;
	opacity: .1;
}
img,
svg{
	width: 100%;
	height: auto;
}

h1,h2,h3,h4,h5,h6{
	// line-height: 2;
}

.bg-fix{
	background-attachment: fixed;
}

.blur{
	filter: blur(4px);
}

.inner{
	position: relative;
	z-index: 1;
	width: 100%;
	max-width: 1200px;
	margin: auto;
	padding: 0 20px;
}
.inner.max1300{
	max-width: 1300px;
}
.inner.max800{
	max-width: 800px;
}

.in{
	position: relative;
}

.pc{display: none;}
.smt{display: block;}

h1{
	display: none;
}
.h-large,
.h-middle,
.h-small,
.h-lead{
	color: #000;
	span{
		display: inline-block;
	}
	small{
		display: block;
		color: $textColor;
		font-size: 13px;
		font-family: $fontBase;
		font-weight: normal;
	}
}
.h-large,
.h-middle,
.h-small{
	font-family: $fontEn;
	font-weight: 400;
	line-height: 2;
	letter-spacing: .2em;
}
.h-large{
	font-size: 25px;
}
.h-middle{
	font-size: 18px;
}
.h-small{
	font-size: 16px;
}
.h-lead{
	font-size: 16px;
	font-family: $fontMincho;
}
.permalink{
	position: absolute;
	top: 0;
	left: 0;
	z-index: 1;
	width: 100%;
	height: 100%;
}

#wrap{

}

header{
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;

}
main{
	display: block;
}

.sec{
	position: relative;
	padding: 12% 0 16%;
	.sec-head{
		text-align: center;
		margin: 0 0 10%;
		p{
			margin: 15px 0 0;
		}
	}
	.sec-body{

	}
	.sec-foot{
		margin: 40px 0 0;
	}
}
footer{
	// padding: 16% 0 calc(12% + 70px);
	padding: 60px 0;
	color: $colorLightGold;
	background-color: #1E1E1E;
	.footer-right{
		text-align: center;
		margin: 30px 0 0;
		p{
			font-size: 13px;
		}
	}
}
#f-logo{
	width: 40%;
	max-width: 180px;
	margin: 0 auto 30px;
	line-height: 0;
}
#f-links{
	ul{
		margin: 0 -20px;
		li{
			border-top: 1px solid rgba(255,255,255,.1);
			a{
				position: relative;
				display: block;
				padding: 15px 20px;
			}
			a:before{
				content: '';
				display: block;
				position: absolute;
				top: 50%;
				right: 20px;
				border: 4px solid transparent;
				border-left: 6px solid $colorLightGold;
				transform: translateY(-50%);
			}
		}
		li:first-child{
			border: 0;
		}
	}
}

address{
	display: inline-block;
	position: relative;
	line-height: 2;
	padding: 0 0 0 1.2em;
	margin: 5px 0 0;
	i{
		position: absolute;
		top: 6px;
		left: 0;
	}
}

.tags{
	margin: 15px 0 0;
	span{
		display: inline-block;
		vertical-align: top;
		font-size: 12px;
		padding: 0 15px;
		margin: 0 5px 5px 0;
		background-color: rgba(0,0,0,.1);
	}
}

.sns{
	margin: 0 0 20px;
	ul{
		line-height: 0;
		li{
			display: inline-block;
			vertical-align: middle;
			margin: 0 7.5px;
			a {
				img{
					width: 35px;
					height: 35px;
					transition: transform .1s;
				}
			}
			a:hover{
				img{
					transform: scale(1.1);
				}
			}
		}
	}
}

#btn_menu{
	cursor: pointer;
	position: fixed;
	top: 0;
	left: 0;
	z-index: 9;
	width: 70px;
	height: 70px;
	background-color: #fff;
	> div,
	> div span {
		display: inline-block;
		transition: all .4s;
		box-sizing: border-box;
	}
	> div {
		position: absolute;
		top: 40%;
		left: 50%;
		width: 25px;
		height: 20px;
		transform: translate(-50%, -50%);
		span {
			position: absolute;
			left: 0;
			width: 100%;
			height: 2px;
			background-color: #000;
		}
		span:nth-of-type(1) {
			top: 0;
		}
		span:nth-of-type(2) {
			top: calc(50% - 1px);
		}
		span:nth-of-type(3) {
			bottom: 0;
		}
		span:nth-of-type(1) {
			-webkit-animation: menu-bar01 .6s forwards;
			animation: menu-bar01 .6s forwards;
		}
		span:nth-of-type(2) {
			transition: all .6s;
			opacity: 1;
		}
		span:nth-of-type(3) {
			-webkit-animation: menu-bar02 .6s forwards;
			animation: menu-bar02 .6s forwards;
		}
	}
	> div:after{
		content: 'MENU';
		font-size: 10px;
		letter-spacing: 0;
		position: relative;
		top: 15px;
		left: -2.5px;
	}
}
#btn_menu.active{
	> div{
		span:nth-of-type(1) {
			-webkit-animation: active-menu-bar01 .6s forwards;
			animation: active-menu-bar01 .6s forwards;
		}
		span:nth-of-type(2) {
			opacity: 0;
		}
		span:nth-of-type(3) {
			-webkit-animation: active-menu-bar03 .6s forwards;
			animation: active-menu-bar03 .6s forwards;
		}
	}
}

#nav-global{
	overflow-y: scroll;
	position: fixed;
	top: 0;
	right: 0;
	z-index: 8;
	width: 100%;
	height: 100%;
	padding: 0 5%;
	color: $colorLightGold;
	background-color: #1e1e1e;
	transform: translateX(100%);
	transition: transform .65s cubic-bezier(0.19, 1, 0.22, 1);
	.in{
		margin: 0 0 50px;
	}
	.nav-head{
		margin: 60px 0 30px;
		#nav-logo{
			width: 40%;
			max-width: 180px;
			margin: auto;
		}
	}
	.nav-body{
		nav{
			ul{
				li{
					border-top: 1px solid rgba(255,255,255,.1);
					a{
						position: relative;
						display: block;
						padding: 15px 0;
						small{
							color: #ddd;
							font-size: 13px;
							font-family: $fontEn;
							font-weight: 300;
							display: block;
						}
						b{

						}
					}
					a:before{
						content: '';
						display: block;
						position: absolute;
						top: 50%;
						right: 10px;
						border: 4px solid transparent;
						border-left: 6px solid $colorLightGold;
						transform: translateY(-50%);
					}
				}
				li:first-child{
					border: 0;
				}
			}
		}
	}
	.nav-foot{
		text-align: center;
		margin: 40px 0 0;
		.sns{
			
		}
		p{
			font-size: 13px;
			margin: 20px 0 0;
			span{
				display: inline-block;
			}
		}
	}
}
#nav-global.on{
	transform: translateX(0);
}

#overlay{
	display: none;
	position: fixed;
	top: 0;
	left: 0;
	z-index: 7;
	width: 100%;
	height: 100%;
	display: none;
	background-color: rgba(0,0,0,0.5);
}

.btns{
	text-align: center;
	a,
	button{
		cursor: pointer;
		display: inline-block;
		width: 100%;
		max-width: 280px;
		padding: 15px 10px;
		background-color: #fff;
		box-shadow: 4px 4px 0 rgba(0,0,0,0.2);
		transition: box-shadow .2s;
		span{
			position: relative;
			z-index: 1;
		}
	}
	a:hover,
	button:hover{
		box-shadow: 0 0 0 rgba(0,0,0,0.2);
	}
	a.btn_opacity,
	button.btn_opacity{
		color: #fff;
		background-color: rgba(255,255,255,.1);
	}
	a.btn_opacity:hover,
	button.btn_opacity:hover{
		background-color: rgba(255,255,255,.2);
	}
}

#copyright{
	position: fixed;
	bottom: 18px;
	left: 15px;
	z-index: 1;
	font-size: 13px;
	opacity: .6;
}

#pagetop{
	display: none;
	cursor: pointer;
	position: fixed;
	bottom: 0;
	right: 0;
	z-index: 6;
	width: 70px;
	height: 70px;
	background-color: #fff;
	span{
		text-align: center;
		display: block;
		position: relative;
		width: 100%;
		height: 100%;
		i{
			color: #000;
			font-size: 10px;
			line-height: 1;
			position: absolute;
			top: 50%;
			left: 50%;
			font-size: 20px;
			transform: translate(-50%, -50%);
		}
	}
}

#opa-logo{
	// display: none;
	position: fixed;
	top: 15px;
	right: 15px;
	width: 80%;
	max-width: 260px;
	z-index: 1;
	opacity: .4;
	line-height: 0;
}

/* area’s Facebook */
/*
.area-fb{
	display: inline-block;
	margin: auto;
	a{
		display: block;
		padding: 5px 15px;
		color: #fff;
		background-color: $colorGold;
		transition: background-color .3s;
		img,
		strong{
			display: inline-block;
			vertical-align: middle;
		}
		img{
			width: 25px;
			height: 25px;
		}
		strong{
			// font-size: 16px;
		}
	}
	a:hover{
		background-color: darken($colorGold, 5%);
	}
	
}
*/

#loading{
	position: fixed;
	top: 0;
	left: 0;
	z-index: 99999;
	width: 100%;
	height: 100%;
	background-color: #fff;
}
#loader{
	position: absolute;
	top: 50%;
	left: 50%;
	width: 38px;
	height: 38px;
	z-index: 9;
	transform: translate(-50%, -50%);
}

/* ======================== lower */
#lower{
	// background: linear-gradient(to right, #efefef 25%, #fff 25.1%);
	main{
		margin-top: 70px;
	}
	#main-view{
		position: relative;
		z-index: 1;
		width: 100%;
		height: 0;
		padding-top: 45%;
		.lower-title{
			// text-align: center;
			position: absolute;
			top: 47.5%;
			left: 50%;
			z-index: 2;
			width: 90%;
			height: auto;
			padding: 0 15px;
			color: #fff;
			transform: translate(-50%, -50%);
			h2{
				color: #fff;
				small{
					color: #fff;
				}
			}
		}
	}
	#main-view:before{
		content: '';
		display: block;
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-color: rgba(0,0,0,.3);
	}
/*
	#main-view:after{
		content: '';
		display: block;
		position: absolute;
		top: 0;
		left: 0;
		width: 50%;
		height: 100%;
		background-color: #000;
	}
*/
}
/*
#lower:before{
	content: '';
	display: block;
	position: fixed;
	top: 0;
	left: 0;
	width: 25%;
	height: 100%;
	background: rgba(0,0,0,.6) url(../svg/common/bg_logo.svg) no-repeat center;
	background-size: cover;
	opacity: .1;
}
*/
#breadcrumb {
	display: none;
}

.pager{
	margin: 40px 0 20px;
	text-align: center;
	a,
	span{
		display: inline-block;
		line-height: 13px;
		padding: 14px 16px;
		margin: 5px;
		background-color: #fff;
		box-shadow: 4px 4px 0 rgba(0,0,0,0.2);
		transition: box-shadow .2s;
	}
	a:hover{
		// color: #fff;
		// background-color: $baseColor;
		box-shadow: 0 0 0 rgba(0,0,0,0.2);
	}
	span{
		color: #fff;
		background-color: #9d9d9d;
	}

	div{
		display: inline-block;
	}
}

#connect-sns{
	display: none;
	position: fixed;
	bottom: 90px;
	right: 10px;
	z-index: 6;
	margin: 0;
	ul{
		li{
			display: block;
			margin: 15px 0 0;
			a{
				display: block;
				position: relative;
				width: 50px;
				height: 50px;
				border-radius: 50%;
				background-color: #fff;
				img{
					position: absolute;
					top: 50%;
					left: 50%;
					width: 25px;
					height: 25px;
					transform: translate(-50%, -50%);
				}
			}
		}
	}
}


@media screen and (min-width: 600px) {
	
	.h-large{
		font-size: 30px;
	}
	.h-middle{
		font-size: 20px;
	}
	.h-small{
		font-size: 17px;
	}
	.h-lead{
		font-size: 18px;
	}

	.sec{
		padding: 10% 0 14%;
		.sec-head{
			margin: 0 0 8%;
		}
	}

	footer{
		// padding: 14% 0 calc(10% + 70px);
	}

/* ======================== lower */
	#lower{
		#main-view{
			padding-top: 40%;
		}
	}

}


@media screen and (min-width: 800px) {
	.pc{display: block;}
	.smt{display: none;}
		
	.h-large{
		font-size: 35px;
	}
	.h-middle{
		font-size: 22px;
	}
	.h-small{
		font-size: 18px;
	}
	.h-lead{
		font-size: 20px;
	}

	.sec{
		padding: 8% 0 12%;
		.sec-head{
			margin: 0 0 6%;
		}
	}

	footer{
		text-align: center;
		padding: 80px 0;
		// padding: 12% 0 calc(8% + 70px);
	}
	#f-links{
		ul{
			margin: 0;
			li{
				border: 0;
				display: inline-block;
				vertical-align: middle;
				a{
					padding: 0 10px 0 0;
				}
				a:before{
					display: none;
					
				}
				a:after{
					content: '/';
					display: inline-block;
					vertical-align: middle;
					padding: 0 0 0 15px;
				}
			}
			li:last-child{
				a:after{
					display: none;
				}
			}

		}
	}

	#nav-global{
		width: 50%;
	}

/* ======================== lower */
	#lower{
		#main-view{
			padding-top: 35%;
		}
	}

	#breadcrumb {
		display: block;
		position: relative;
		z-index: 1;
		white-space: nowrap;
		font-family: $fontBase;
		// box-sizing: content-box;
		margin-bottom: -20px;
		padding: 15px 20px 0;
		ol {
			overflow: hidden;
			li {
				float: left;
				font-size: 13px;
				margin: 0px 10px 0px 0px;
				a {
					color: #0071bc;
					background: transparent url(../img/common/arw_bc.png) no-repeat scroll right 5px;
					padding: 0px 14px 0px 0px;
				}
				a:hover{
					text-decoration: underline;
				}
			}
			li.categorize {
				background: transparent url(../img/common/arw_bc.png) no-repeat scroll right 8px;
				padding: 0px 14px 0px 0px;
			}
		}
	}

}


@media screen and (min-width: 1000px) {
	.h-large{
		font-size: 40px;
	}
	.h-middle{
		font-size: 24px;
	}
	.h-small{
		font-size: 19px;
	}
	.h-lead{
		font-size: 22px;
	}

	.sec{
		padding: 6% 0 10%;
		.sec-head{
			margin: 0 0 4%;
		}
	}

	footer{
		// text-align: left;
		// padding: 10% 0 calc(6% + 80px);
		.footer-right{
			position: absolute;
			bottom: 0;
			right: 20px;
			text-align: right;
		}
	}
	#f-logo{
		margin: 0 auto 30px;
	}

	#opa-logo{
		top: 22px;
		right: 20px;
	}

	#btn_menu{
		width: 80px;
		height: 80px;
	}

	#pagetop{
		width: 80px;
		height: 80px;
	}

	#connect-sns{
		bottom: 100px;
		ul{
			li{
				a{
					width: 60px;
					height: 60px;
				}
			}
		}
	}

/* ======================== lower */
	#lower{
		main{
			margin-top: 80px;
		}
		#main-view{
			padding-top: 30%;
		}
	}

}


@media screen and (min-width: 1200px) {
	.h-large{
		// font-size: 45px;
	}
	.h-middle{
		font-size: 26px;
	}
	.h-small{
		font-size: 20px;
	}
	.h-lead{
		font-size: 24px;
	}

	.sec{
		padding: 100px 0 120px;
		.sec-head{
			margin: 0 0 60px;
		}
	}

	footer{
		// padding: 100px 0 180px;
	}

/* ======================== lower */
	#lower{
		#main-view{
			padding-top: 25%;
		}
	}

}



@-webkit-keyframes menu-bar01 {
	0% { -webkit-transform: translateY(9px) rotate(45deg); }
	50% { -webkit-transform: translateY(9px) rotate(0); }
	100% { -webkit-transform: translateY(0) rotate(0); }
}
@keyframes menu-bar01 {
	0% { transform: translateY(9px) rotate(45deg); }
	50% { transform: translateY(9px) rotate(0); }
	100% { transform: translateY(0) rotate(0); }
}

@-webkit-keyframes menu-bar02 {
	0% { -webkit-transform: translateY(-9px) rotate(-45deg); }
	50% { -webkit-transform: translateY(-9px) rotate(0); }
	100% { -webkit-transform: translateY(0) rotate(0); }
}
@keyframes menu-bar02 {
	0% { transform: translateY(-9px) rotate(-45deg); }
	50% { transform: translateY(-9px) rotate(0); }
	100% { transform: translateY(0) rotate(0); }
}

@-webkit-keyframes active-menu-bar01 {
	0% { -webkit-transform: translateY(0) rotate(0); }
	50% { -webkit-transform: translateY(9px) rotate(0); }
	100% { -webkit-transform: translateY(9px) rotate(45deg); }
}
@keyframes active-menu-bar01 {
	0% { transform: translateY(0) rotate(0); }
	50% { transform: translateY(9px) rotate(0); }
	100% { transform: translateY(9px) rotate(45deg); }
}

@-webkit-keyframes active-menu-bar03 {
	0% { -webkit-transform: translateY(0) rotate(0); }
	50% { -webkit-transform: translateY(-9px) rotate(0); }
	100% { -webkit-transform: translateY(-9px) rotate(-45deg); }
}
@keyframes active-menu-bar03 {
	0% { transform: translateY(0) rotate(0); }
	50% { transform: translateY(-9px) rotate(0); }
	100% { transform: translateY(-9px) rotate(-45deg); }
}


.key-fade{
	opacity: 0;
	transition: opacity 2s;
}
.key-fade.on{
	opacity: 1;
}


.key-fadeUp{
	position: relative;
	top: 50px;
	opacity: 0;
	transition: top 1s,
				opacity 1s;
}
.key-fadeUp.on{
	top: 0;
	opacity: 1;
}